import { gql } from '@apollo/client';
import { Box, Card, Text } from '@spaceship-fspl/components';
import type { WebAppMoneyDashboardPage_contact_account } from '@spaceship-fspl/graphql/src/__generated__/WebAppMoneyDashboardPage';
import { FeatherChevronRightIcon } from '@spaceship-fspl/icons-web';
import { getColor, getSpace } from '@spaceship-fspl/styles';
import { TestId } from 'helpers/test-ids';
import React from 'react';
import styled, { css } from 'styled-components';

export const NovaPendingOrdersCard_AccountFragment = gql`
  fragment NovaPendingOrdersCard_AccountFragment on Account {
    id
    novaProductInstance {
      id
      pendingOrders: transactions(
        statuses: [SUBMITTED, PROCESSING, ORDERED, IN_MARKET]
        types: $novaPendingOrderTypes
      ) @connection(key: "pending-orders-all", filter: ["statuses", "types"]) {
        pageInfo {
          total
        }
      }
    }
  }
`;

export const MoneyPendingOrders: React.FC<{
  account?: WebAppMoneyDashboardPage_contact_account | null;
  onClick: () => void;
}> = ({ account, onClick }) => {
  const pendingOrdersCount =
    account?.novaProductInstance?.pendingOrders?.pageInfo?.total ?? 0;

  if (pendingOrdersCount === 0) {
    return null;
  }

  return (
    <ActionItem
      data-testid={TestId.MONEY_PENDING_ORDERS}
      role="button"
      onClick={onClick}
    >
      <Card aria-label="pending sell orders">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY="sm"
          paddingX="md"
          marginBottom="sm"
        >
          <Text variant={2} isBold>
            Pending sell orders
            <PendingOrders pendingOrdersCount={pendingOrdersCount}>
              {pendingOrdersCount}
            </PendingOrders>
          </Text>

          <FeatherChevronRightIcon
            testId="chevron-right-icon"
            color="indigo.070"
            size="md"
          />
        </Box>
      </Card>
    </ActionItem>
  );
};

const ActionItem = styled.div`
  cursor: pointer;
`;

const PendingOrders = styled.span<{ pendingOrdersCount: number }>`
  color: ${getColor('neutral.000')};
  background-color: ${getColor('pink.030')};
  border-radius: ${getSpace('md')};
  padding-left: ${getSpace('xxs')};
  padding-right: ${getSpace('xxs')};
  padding-top: ${getSpace('xxxs')};
  padding-bottom: ${getSpace('xxxs')};
  margin-left: ${getSpace('xxs')};

  ${({ pendingOrdersCount }) =>
    pendingOrdersCount > 9
      ? css`
          padding-left: ${getSpace('xs')};
          padding-right: ${getSpace('xs')};
        `
      : css``}
`;
