import { type RouteComponentProps, useNavigate } from '@reach/router';
import {
  Box,
  Columns,
  Divider,
  Heading,
  Spinner,
  Stack,
  TextLink,
} from '@spaceship-fspl/components';
import {
  ScheduleFrequency,
  useCreatePaymentSchedule,
  useGetPortfolioById,
} from '@spaceship-fspl/graphql';
import {
  DATE_FORMAT_TRANSACTIONS_LONG,
  formatCurrency,
  formatDate,
} from '@spaceship-fspl/helpers';
import { Button } from 'components/button';
import { LabeledField } from 'components/labeled-field';
import { PageContainer } from 'components/layouts/page';
import { useNotifications } from 'contexts/notifications';
import {
  GENERIC_WARNING_MESSAGE,
  VOYAGER_DEPOSIT_LIMIT_EXCEEDED,
} from 'helpers/errors';
import { addRumError } from 'helpers/monitoring';
import { voyagerPortfolios } from 'helpers/portfolios';
import { TestId } from 'helpers/test-ids';
import React from 'react';

import { Routes } from './routes';

type VoyagerDepositConfirmProps = {
  productId: string;
  location: {
    state: {
      audAmount: string;
      etaDate: Date;
    };
  };
};

export const VoyagerDepositConfirm: React.FC<
  React.PropsWithChildren<RouteComponentProps<VoyagerDepositConfirmProps>>
> = ({ location, productId = '' }) => {
  const notifications = useNotifications();
  const navigate = useNavigate();

  const audAmount = location?.state?.audAmount;
  const etaDate = location?.state?.etaDate
    ? new Date(location?.state?.etaDate)
    : undefined;

  const [createPaymentSchedule, createPaymentScheduleMeta] =
    useCreatePaymentSchedule();

  const portfolio = useGetPortfolioById({
    variables: {
      productId,
    },
  });

  const submissionDisabled =
    createPaymentScheduleMeta.loading || audAmount == null;

  const handleConfirm = async (): Promise<void> => {
    if (audAmount != null) {
      createPaymentSchedule({
        variables: {
          input: {
            productId,
            audAmount,
            frequency: ScheduleFrequency.ONE_TIME,
          },
        },
        onCompleted: () => {
          navigate(`${Routes.VOYAGER_DEPOSIT_SUCCESS}/${productId}`, {
            state: {
              productId,
              audAmount,
              etaDate,
            },
          });
        },
        onError: (error) => {
          addRumError({ error });

          const limitExceeded = (error as Error)?.message?.includes(
            'max number of daily deposits allowed exceeded',
          );

          notifications.popToast({
            level: limitExceeded ? 'error' : 'warning',
            message: limitExceeded
              ? VOYAGER_DEPOSIT_LIMIT_EXCEEDED
              : GENERIC_WARNING_MESSAGE,
          });
        },
      });
    }
  };

  return (
    <PageContainer data-testid={TestId.VOYAGER_ONE_OFF_CONFIRM_PAGE}>
      <Columns alignX="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3, lg: 1 / 2, xl: 1 / 3 }}>
          <Heading variant={3} align="center">
            Confirm your investment
          </Heading>

          <Box
            backgroundColor="neutral.000"
            padding="lg"
            borderRadius="sm"
            boxShadow="sm"
            marginTop="md"
          >
            <Stack spaceY="sm">
              <LabeledField label="Portfolio" align="left" size="sm">
                {portfolio ? voyagerPortfolios[portfolio].title : '—'}
              </LabeledField>

              <Divider color="neutral.030" />

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <LabeledField label="Amount" align="left" size="sm">
                  {formatCurrency(audAmount)}
                </LabeledField>

                <TextLink
                  hideUnderline
                  color="indigo.070"
                  onClick={() => {
                    navigate(`${Routes.VOYAGER_DEPOSIT}/${productId}`);
                  }}
                >
                  Edit
                </TextLink>
              </Box>

              <Divider color="neutral.030" />

              <LabeledField label="Investment complete" align="left" size="sm">
                ETA {formatDate(etaDate, DATE_FORMAT_TRANSACTIONS_LONG)}
              </LabeledField>
            </Stack>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="xl"
          >
            <Stack spaceY="xs" alignX="center">
              <Button
                variant="primary"
                size="lg"
                isDisabled={submissionDisabled}
                isLoading={createPaymentScheduleMeta.loading}
                trackingProperties={{
                  name: 'voyager_deposit_one_off_confirm_submit',
                }}
                onClick={handleConfirm}
              >
                {createPaymentScheduleMeta.loading ? (
                  <Spinner size="sm" />
                ) : (
                  'Confirm'
                )}
              </Button>

              <Box marginTop="sm">
                <TextLink
                  hideUnderline
                  color="indigo.070"
                  onClick={() => {
                    navigate(Routes.VOYAGER_DASHBOARD);
                  }}
                >
                  Cancel
                </TextLink>
              </Box>
            </Stack>
          </Box>
        </Columns.Column>
      </Columns>
    </PageContainer>
  );
};
