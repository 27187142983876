
/**
 * THIS FILE IS GENERATED. DO NOT EDIT!
 * @see generate-colors.js
 */

export type Color = "neutral.100" | "neutral.085" | "neutral.080" | "neutral.070" | "neutral.050" | "neutral.030" | "neutral.015" | "neutral.000" | "green.100" | "indigo.100" | "indigo.090" | "indigo.080" | "indigo.070" | "indigo.060" | "indigo.050" | "indigo.040" | "indigo.025" | "indigo.020" | "indigo.015" | "indigo.010" | "pink.100" | "pink.080" | "pink.050" | "pink.030" | "blue.100" | "blue.080" | "blue.070" | "blue.050" | "blue.030" | "mint.100" | "mint.050" | "mint.030" | "mint.015" | "red.100" | "red.030" | "red.020" | "red.015" | "gold.100" | "gold.050" | "gold.030" | "gold.015" | "black.100" | "product.voyager" | "product.super" | "product.etf" | "product.stocks" | "misc.bpay";

export const colors = {
  "neutral": {
    "100": "#12003B",
    "085": "#51516C",
    "080": "#6F708C",
    "070": "#BEBECB",
    "050": "#E3E1E8",
    "030": "#EFEEF2",
    "015": "#F5F5F8",
    "000": "#FFFFFF"
  },
  "green": {
    "100": "#0CB061"
  },
  "indigo": {
    "100": "#250F5A",
    "090": "#2F0A87",
    "080": "#4110B7",
    "070": "#600CE8",
    "060": "#701AEB",
    "050": "#930EFF",
    "040": "#B359FB",
    "025": "#CDB6FF",
    "020": "#DBAFFE",
    "015": "#F3E4FF",
    "010": "#EDE4FF"
  },
  "pink": {
    "100": "#FF148B",
    "080": "#E100CA",
    "050": "#CA00E3",
    "030": "#CA4FFC"
  },
  "blue": {
    "100": "#003676",
    "080": "#1A7AB4",
    "070": "#36A8C8",
    "050": "#31DBE3",
    "030": "#07EFFE"
  },
  "mint": {
    "100": "#00AAA0",
    "050": "#08D2B2",
    "030": "#4CEBD2",
    "015": "#C9FEEF"
  },
  "red": {
    "100": "#E11B03",
    "030": "#FF4840",
    "020": "#FF8282",
    "015": "#FFD3D0"
  },
  "gold": {
    "100": "#F4A305",
    "050": "#FFC600",
    "030": "#FFDF52",
    "015": "#FFF0B0"
  },
  "black": {
    "100": "#000000"
  },
  "product": {
    "voyager": "#930EFF",
    "super": "#250F5A",
    "etf": "#8468F6",
    "stocks": "#06CEDB"
  },
  "misc": {
    "bpay": "#002D6A"
  }
};
  