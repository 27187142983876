import { Box, Heading, Stack, Text } from '@spaceship-fspl/components';
import { StreamlineFaceId4Icon } from '@spaceship-fspl/icons-web';
import { Button } from 'components/button';
import type { TrackingProperties } from 'helpers/analytics';
import React from 'react';

interface PendingVerificationProps {
  onBack?: {
    trackingProperties: TrackingProperties;
    onClick: () => void;
  };
}

export const PendingVerification: React.FC<PendingVerificationProps> = ({
  onBack,
}) => {
  return (
    <Stack spaceY="md" alignX="center">
      <StreamlineFaceId4Icon strokeWidth={3} color="indigo.070" size="xxxl" />

      <Stack spaceY="xs">
        <Heading variant={3} component="h1" align="center">
          We&apos;re still verifying your ID.
        </Heading>

        <Text variant={1} align="center">
          Once this is complete, you will be able to make an investment
        </Text>
      </Stack>

      {onBack ? (
        <Box marginTop="md">
          <Button
            variant="primary"
            size="lg"
            trackingProperties={onBack.trackingProperties}
            onClick={onBack.onClick}
          >
            Go back
          </Button>
        </Box>
      ) : null}
    </Stack>
  );
};
