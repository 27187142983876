import {
  SharedDynamicConfigKeys,
  useNumber,
} from '@spaceship-fspl/dynamic-config';
import {
  cleanNumber,
  formatCurrency,
  formatCurrencyWithNoDecimal,
  isValidDollarAmount,
} from '@spaceship-fspl/helpers';

import { InvestmentType } from './constants';

export const MIN_INVESTMENT_AMOUNT_DEFAULT = 0.01;
export const MIN_INVESTMENT_PLAN_AMOUNT_DEFAULT = 5;
export const MAX_INVESTMENT_AMOUNT_DEFAULT = 500000;

export const useInvestmentValidation = (
  type: InvestmentType,
): ((value: string) => boolean | string) => {
  const maxAmountConfig = useNumber(
    SharedDynamicConfigKeys.MAX_INVESTMENT_AMOUNT,
    MAX_INVESTMENT_AMOUNT_DEFAULT,
  );

  const minInvestmentPlanAmountConfig = useNumber(
    SharedDynamicConfigKeys.MIN_INVESTMENT_PLAN_AMOUNT,
    MIN_INVESTMENT_PLAN_AMOUNT_DEFAULT,
  );

  // minInvestmentPlanAmountConfig does not apply to one-time deposits
  const minAmount =
    type === InvestmentType.ONE_OFF
      ? MIN_INVESTMENT_AMOUNT_DEFAULT
      : minInvestmentPlanAmountConfig;

  return (value: string): boolean | string => {
    const cleanValue = cleanNumber(value);
    const amount = Number(cleanValue);

    if (isNaN(amount) || !isValidDollarAmount(cleanValue)) {
      return 'Amount is required';
    }

    if (amount < minAmount) {
      return `Minimum amount is ${formatCurrency(minAmount)}`;
    }
    if (amount > maxAmountConfig) {
      return `Maximum amount is ${formatCurrencyWithNoDecimal(
        maxAmountConfig,
      )}`;
    }

    return true;
  };
};
