import {
  Box,
  Button,
  Columns,
  Stack,
  Text,
  TextLink,
} from '@spaceship-fspl/components';
import { StreamlineAlertTriangleIcon } from '@spaceship-fspl/icons-web';
import { Color } from '@spaceship-fspl/styles';
import { CenterOnboardingContainer } from 'components/layouts/onboarding';
import {
  CenterPageContainer,
  PageFormButtonContainer,
  PageFormContinueButton,
  PageHeading,
} from 'components/layouts/page';
import { useIntercom } from 'contexts/intercom';
import { TrackingProperties } from 'helpers/analytics';
import React from 'react';

export interface ErrorProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  buttonText?: string;
  onContinue: {
    onClick: () => void;
    trackingProperties: TrackingProperties;
  };
  isOnboarding?: boolean;
  iconColor?: Color;
  showContactSupportButton?: boolean;
  secondaryButton?: React.ReactNode;
}

export const InlineContactSupportMessage: React.FC<{
  subtitle?: string;
}> = ({
  subtitle = 'Please try again in a few moments. If this keeps happening,',
}) => {
  const intercom = useIntercom();

  return (
    <Text variant={1} align="center">
      <Text variant={1} component="span">
        {subtitle}{' '}
      </Text>
      <TextLink color="indigo.070" onClick={() => intercom.pop()}>
        contact support.
      </TextLink>
    </Text>
  );
};

export const Error: React.FC<React.PropsWithChildren<ErrorProps>> = ({
  title,
  subtitle,
  buttonText,
  onContinue,
  isOnboarding,
  secondaryButton,
  iconColor = 'gold.100',
  showContactSupportButton = false,
}) => {
  const Container = isOnboarding
    ? CenterOnboardingContainer
    : CenterPageContainer;

  const intercom = useIntercom();

  return (
    <Container>
      <Columns alignX="center" alignY="center">
        <Columns.Column width={{ xs: 1, md: 2 / 3 }}>
          <Stack spaceY="sm" alignX="center">
            <StreamlineAlertTriangleIcon
              size="xxxl"
              color={iconColor}
              strokeWidth={3}
            />
            <PageHeading title={title} subtitle={subtitle} />
          </Stack>

          <PageFormButtonContainer>
            <PageFormContinueButton {...onContinue}>
              {buttonText || 'Continue'}
            </PageFormContinueButton>

            {secondaryButton ? (
              <Box marginTop="sm">{secondaryButton}</Box>
            ) : null}

            {showContactSupportButton && (
              <Button
                variant="secondary"
                size="lg"
                onClick={() => intercom.pop()}
              >
                Contact support
              </Button>
            )}
          </PageFormButtonContainer>
        </Columns.Column>
      </Columns>
    </Container>
  );
};
