export enum Routes {
  INDEX = '/',
  ACCOUNT_USER_DETAILS = '/account/details/user',
  ACCOUNT_VOYAGER_DETAILS = '/account/details/voyager',
  ACCOUNT_SUPER_DETAILS = '/account/details/super',
  ACCOUNT_CONFIRM_DETAILS = '/account/details/confirm',
  ACCOUNT_BANK_ACCOUNT = '/account/bank-account',
  ACCOUNT_SECURITY_CHECK = '/account/security-check',
  ACCOUNT_CHANGE_PASSWORD = '/account/change-password',
  ACCOUNT_MFA_REGISTRATION = '/account/verify-phone',
  ACCOUNT_MFA_REGISTRATION_TOO_MANY_ATTEMPTS = '/account/verify-phone/too-many-attempts',
  ACCOUNT_MFA_REGISTRATION_PHONE_SUCCESS = '/account/verify-phone/success',
  ACCOUNT_MFA_REGISTRATION_REQUIRED_INTRO = '/account/verify-phone-required/intro',
  ACCOUNT_MFA_REGISTRATION_REQUIRED = '/account/verify-phone-required',
  ACCOUNT_MFA_REGISTRATION_REQUIRED_TOO_MANY_ATTEMPTS = '/account/verify-phone-required/too-many-attempts',
  ACCOUNT_MFA_REGISTRATION_REQUIRED_SUCCESS = '/account/verify-phone-required/success',
  BANK_ACCOUNT = 'bank-account',
  BANK_ACCOUNT_LOGIN = 'login',
  BANK_ACCOUNT_2FA = '2fa',
  BANK_ACCOUNT_SELECT_ACCOUNT = 'select-account',
  BANK_ACCOUNT_MANUAL = 'manual',
  BANK_ACCOUNT_SELECT_METHOD = 'select-method',
  BANK_ACCOUNT_SUCCESS = 'success',
  BOOSTS = '/voyager/invest/boosts',
  BOOSTS_INTRO = '/voyager/invest/boosts/intro',
  BOOSTS_BASIQ_LOGIN = '/voyager/invest/boosts/basiq/login',
  BOOSTS_BASIQ_SELECT = '/voyager/invest/boosts/basiq/select',
  BOOSTS_BASIQ_EDIT = '/voyager/invest/boosts/basiq/edit',
  BOOSTS_BASIQ_INSTITUTIONS = '/voyager/invest/boosts/basiq/institutions',
  BOOSTS_BASIQ_RELINK = '/voyager/invest/boosts/basiq/relink',
  BOOSTS_BASIQ_UNLINK = '/voyager/invest/boosts/basiq/unlink',
  BOOSTS_BASIQ_ERROR = '/voyager/invest/boosts/basiq/error',
  BOOSTS_BASIQ_SUCCESS = '/voyager/invest/boosts/basiq/success',
  BOOSTS_DASHBOARD_HOME = '/voyager/invest/boosts/dashboard/home',
  BOOSTS_DASHBOARD_BOOSTS = '/voyager/invest/boosts/dashboard/boosts',
  BOOSTS_DASHBOARD_SETTINGS = '/voyager/invest/boosts/dashboard/settings',
  BOOSTS_RECIPE_SELECT = '/voyager/invest/boosts/recipe/select',
  BOOSTS_RECIPE_SETUP = '/voyager/invest/boosts/recipe/setup',
  BOOSTS_RECIPE_SETUP_SUCCESS = '/voyager/invest/boosts/recipe/setup/success',
  BOOSTS_SCHEDULED_DETAILS = '/voyager/invest/boosts/scheduled-details',
  BOOSTS_TERMS_AND_CONDITIONS = '/voyager/invest/boosts/terms-and-conditions',
  SUPER_DASHBOARD = '/super',
  SUPER_BALANCE = '/super/balance',
  SUPER_COMING_SOON = '/super/coming-soon',
  SUPER_COMING_SOON_CONSOLIDATE = '/super/consolidate/coming-soon',
  SUPER_UNIT_PRICE = '/super/unit-price',
  SUPER_TRANSACTIONS = '/super/transactions',
  SUPER_IMPORTANT_DOCUMENTS = '/super/important-documents',
  SUPER_SELECT_INVESTMENT_OPTION = '/super/select-investment',
  SUPER_SELECT_INVESTMENT_OPTION_SUCCESS = '/super/select-investment/success',
  SUPER_CHANGE_INVESTMENT_OPTION = '/super/change-investment',
  SUPER_CHANGE_INVESTMENT_OPTION_SUCCESS = '/super/change-investment/success',
  SUPER_TFN = '/super/tax-file-number',
  SUPER_CONTRIBUTIONS = '/super/contributions',
  SUPER_CONTRIBUTIONS_VOLUNTARY = '/super/contributions/voluntary',
  SUPER_CONTRIBUTIONS_EMPLOYER = '/super/contributions/employer',
  SUPER_CONTRIBUTIONS_EMPLOYER_SUCCESS = '/super/contributions/employer/success',
  SUPER_CONSOLIDATE = '/super/consolidate',
  SUPER_SUPERMATCH = '/super/supermatch',
  SUPER_SUPERMATCH_TFN = '/super/supermatch/tax-file-number',
  SUPER_SUPERMATCH_RESULTS = '/super/supermatch/results',
  SUPER_SUPERMATCH_TERMS = '/super/supermatch/terms',
  SUPER_SUPERMATCH_SUCCESS = '/super/supermatch/success',
  SUPER_SUPERMATCH_ERROR = '/super/supermatch/error',
  SUPER_SUPERMATCH_GREENID = '/super/supermatch/id-verification',
  SUPER_SUPERMATCH_GREENID_AUSTRALIA_PASSPORT = '/super/supermatch/id-verification/australia-passport',
  SUPER_SUPERMATCH_GREENID_CITIZENSHIP_CERTIFICATE = '/super/supermatch/id-verification/citizenship-certificate',
  SUPER_SUPERMATCH_GREENID_DRIVER_LICENCE = '/super/supermatch/id-verification/driver-licence',
  SUPER_SUPERMATCH_GREENID_FOREIGN_PASSPORT = '/super/supermatch/id-verification/foreign-passport',
  SUPER_SUPERMATCH_GREENID_MEDICARE = '/super/supermatch/id-verification/medicare-card',
  SUPER_SUPERMATCH_GREENID_UPLOAD = '/super/supermatch/id-verification/upload',
  SUPER_REFERRAL_SHARE_CODE = '/super/referrals',
  SUPER_FHSS = '/super/fhss',
  SUPER_LINK = '/super-link',
  SUPER_LINK_RESET_PASSWORD = '/super-link/reset-password',
  SUPER_LINK_RESET_PASSWORD_NEW = '/super-link/reset-password/new',
  SUPER_LINK_MISMATCH = '/super-link/mismatch',
  SUPER_LINK_ERROR = '/super-link/error',
  SUPER_LINK_SUCCESS = '/super-link/success',
  LOGIN = '/login',
  LOGIN_CHALLENGE = '/login/challenge',
  LOGIN_CHALLENGE_TOO_MANY_ATTEMPTS = '/login/too-many-attempts',
  ONBOARDING_MOBILE_APPS = '/onboarding/mobile-apps',
  PASSWORD_RESET = '/reset-password',
  PASSWORD_RESET_VERIFY = '/reset-password/verify',
  PASSWORD_RESET_NEW_PASSWORD = '/reset-password/new-password',
  PASSWORD_RESET_SUPER_VERIFY = '/reset-password/super/verify',
  PORTFOLIO_ADD = '/portfolio/add',
  PORTFOLIO_ADD_PRE_APPROVED = '/portfolio/add/pre-approved',
  PORTFOLIO_ADD_TMD = '/portfolio/add/tmd',
  PORTFOLIO_ADD_INVEST_DEPOSIT = '/portfolio/add/invest/deposit',
  PORTFOLIO_ADD_INVEST_INVESTMENT_PLAN = '/portfolio/add/invest/investment-plan',
  PORTFOLIO_ADD_SUMMARY = '/portfolio/add/summary',
  PORTFOLIO_ADD_SUCCESS = '/portfolio/add/success',
  RESUME_ONBOARDING = '/onboarding',
  SIGNUP_PRODUCT_SELECT = '/signup',
  SIGNUP = '/signup',
  SIGNUP_DETAILS = '/signup/details',
  SIGNUP_MFA_REGISTRATION = '/signup/verify-phone',
  SIGNUP_MFA_REGISTRATION_TOO_MANY_ATTEMPTS = '/signup/verify-phone/too-many-attempts',
  SIGNUP_PRODUCT = '/signup/choose-product',
  SUPER_SIGNUP = '/signup/super',
  SUPER_SIGNUP_REFERRED = '/signup/super/referred',
  SUPER_SIGNUP_DETAILS = '/signup/super/details',
  SUPER_SIGNUP_PORTFOLIO = '/signup/super/choose-investment',
  SUPER_SIGNUP_GENDER = '/signup/super/gender',
  SUPER_SIGNUP_TFN = '/signup/super/tax-file-number',
  SUPER_SIGNUP_ERROR = '/signup/super/error',
  SUPER_SIGNUP_SUCCESS = '/signup/super/onboarding/success',
  SUPER_SIGNUP_CONSOLIDATE = '/signup/super/onboarding/consolidate',
  SUPER_SIGNUP_SUPERMATCH = '/signup/super/onboarding/supermatch',
  SUPER_SIGNUP_SUPERMATCH_TFN = '/signup/super/onboarding/supermatch/tax-file-number',
  SUPER_SIGNUP_SUPERMATCH_RESULTS = '/signup/super/onboarding/supermatch/results',
  SUPER_SIGNUP_SUPERMATCH_TERMS = '/signup/super/onboarding/supermatch/terms',
  SUPER_SIGNUP_SUPERMATCH_SUCCESS = '/signup/super/onboarding/supermatch/success',
  SUPER_SIGNUP_SUPERMATCH_ERROR = '/signup/super/onboarding/supermatch/error',
  SUPER_SIGNUP_SUPERMATCH_GREENID = '/signup/super/onboarding/supermatch/id-verification',
  SUPER_SIGNUP_SUPERMATCH_GREENID_AUSTRALIA_PASSPORT = '/signup/super/onboarding/supermatch/id-verification/australia-passport',
  SUPER_SIGNUP_SUPERMATCH_GREENID_CITIZENSHIP_CERTIFICATE = '/signup/super/onboarding/supermatch/id-verification/citizenship-certificate',
  SUPER_SIGNUP_SUPERMATCH_GREENID_DRIVER_LICENCE = '/signup/super/onboarding/supermatch/id-verification/driver-licence',
  SUPER_SIGNUP_SUPERMATCH_GREENID_FOREIGN_PASSPORT = '/signup/super/onboarding/supermatch/id-verification/foreign-passport',
  SUPER_SIGNUP_SUPERMATCH_GREENID_MEDICARE = '/signup/super/onboarding/supermatch/id-verification/medicare-card',
  SUPER_SIGNUP_SUPERMATCH_GREENID_UPLOAD = '/signup/super/onboarding/supermatch/id-verification/upload',
  SUPER_SIGNUP_EMPLOYER_CONTRIBUTION = '/signup/super/onboarding/employer-contribution',
  SUPER_SIGNUP_EMPLOYER_CONTRIBUTION_SUCCESS = '/signup/super/onboarding/employer-contribution/success',
  SUPER_SIGNUP_MOBILE_APPS = '/signup/super/onboarding/mobile-apps',
  UP = '../',
  VOYAGER_COMING_SOON = '/voyager/coming-soon',
  VOYAGER_SIGNUP = '/signup/voyager',
  VOYAGER_SIGNUP_DETAILS = '/signup/voyager/details',
  VOYAGER_ONBOARDING_BANK_ACCOUNT = '/onboarding/voyager/bank-account',
  VOYAGER_ONBOARDING_GREENID = '/onboarding/voyager/id-verification',
  VOYAGER_ONBOARDING_GREENID_AUSTRALIA_PASSPORT = '/onboarding/voyager/id-verification/australia-passport',
  VOYAGER_ONBOARDING_GREENID_CITIZENSHIP_CERTIFICATE = '/onboarding/voyager/id-verification/citizenship-certificate',
  VOYAGER_ONBOARDING_GREENID_DRIVER_LICENCE = '/onboarding/voyager/id-verification/driver-licence',
  VOYAGER_ONBOARDING_GREENID_FOREIGN_PASSPORT = '/onboarding/voyager/id-verification/foreign-passport',
  VOYAGER_ONBOARDING_GREENID_MEDICARE = '/onboarding/voyager/id-verification/medicare-card',
  VOYAGER_ONBOARDING_GREENID_UPLOAD = '/onboarding/voyager/id-verification/upload',
  VOYAGER_ONBOARDING_DEPOSIT = '/onboarding/voyager/invest/deposit',
  VOYAGER_ONBOARDING_INVESTMENT_PLAN = '/onboarding/voyager/invest/investment-plan',
  VOYAGER_ONBOARDING_INVEST = '/onboarding/voyager/invest',
  VOYAGER_ONBOARDING_PORTFOLIO = '/onboarding/voyager/portfolio',
  VOYAGER_ONBOARDING_PRE_APPROVED = '/onboarding/voyager/pre-approved',
  VOYAGER_ONBOARDING_SUMMARY = '/onboarding/voyager/summary',
  VOYAGER_ONBOARDING_TAX_RESIDENCY = '/onboarding/voyager/tax-residency',
  VOYAGER_ONBOARDING_TMD_FORM = '/onboarding/voyager/tmd',
  VOYAGER_ONBOARDING_TMD_SUCCESS = '/onboarding/voyager/tmd/success',
  VOYAGER_ONBOARDING_CROSS_SELL_START = '/onboarding/voyager/start',
  VOYAGER_DASHBOARD = '/voyager',
  VOYAGER_UNIT_PRICE = '/voyager/unit-price',
  VOYAGER_INVEST_OPTIONS = '/voyager/invest',
  VOYAGER_DEPOSIT = '/voyager/invest/one-off',
  VOYAGER_DEPOSIT_CONFIRM = '/voyager/invest/one-off/confirm',
  VOYAGER_DEPOSIT_SUCCESS = '/voyager/invest/one-off/success',
  VOYAGER_INVESTMENT_PLAN_PORTFOLIOS = '/voyager/invest/plan/portfolios',
  VOYAGER_INVESTMENT_PLAN = '/voyager/invest/plan',
  VOYAGER_INVESTMENT_PLAN_SUCCESS = '/voyager/invest/plan/success',
  VOYAGER_WITHDRAW = '/voyager/withdraw',
  VOYAGER_WITHDRAW_CONFIRM = '/voyager/withdraw/confirm',
  VOYAGER_WITHDRAW_SUCCESS = '/voyager/withdraw/success',
  VOYAGER_REFERRALS = '/voyager/referrals',
  VOYAGER_REFERRALS_ENTER_CODE = '/voyager/referrals/enter-code',
  VOYAGER_TRANSACTIONS = '/voyager/transactions',
  VOYAGER_TAX_RESIDENCY = '/voyager/tax-residency',
  VOYAGER_TAX_RESIDENCY_COUNTRIES = '/voyager/tax-residency/countries',
  VOYAGER_TAX_RESIDENCY_PRIMARY_ADDRESS = '/voyager/tax-residency/primary-address',
  VOYAGER_TAX_RESIDENCY_INTRO = '/voyager/tax-residency/intro',
  VOYAGER_TAX_RESIDENCY_ADD = '/voyager/tax-residency/add',
  VOYAGER_TAX_RESIDENCY_ADD_COUNTRIES = '/voyager/tax-residency/add/countries',
  VOYAGER_TAX_RESIDENCY_ADD_PRIMARY_ADDRESS = '/voyager/tax-residency/add/primary-address',
  VOYAGER_TFN = '/voyager/tax-file-number',
  VOYAGER_TFN_EXEMPTION = '/voyager/tax-file-number/exemption',
  VOYAGER_TFN_LEARN_MORE = '/voyager/tax-file-number/learn-more',
  VOYAGER_PORTFOLIO = '/voyager/portfolio',
  VOYAGER_PORTFOLIO_ABOUT_EARTH = '/voyager/portfolio/about-earth',
  ETF_DASHBOARD = '/etfs',
  STOCKS_DASHBOARD = '/stocks',
  TAX_RESIDENCY_US_INVESTING_WARNING = '/voyager/tax-residency/us-investing-warning',
  VOYAGER_TAX_RESIDENCY_NOT_EDITABLE = '/voyager/tax-residency/not-editable',
  MONEY = '/money',
  MONEY_WITHDRAW = '/money/withdraw',
  MONEY_WITHDRAW_CONFIRM = '/money/withdraw/confirm',
  MONEY_WITHDRAW_SUCCESS = '/money/withdraw/success',
  MONEY_DEPOSIT = '/money/add',
  MONEY_DEPOSIT_SUCCESS = '/money/add/success',
}
